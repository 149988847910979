@font-face {
  font-family: fortnite;
  src: url(fonts/BurbankBigRegular-Black.woff) format("woff") 
}

@font-face {
  font-family: fortnite-cyrillic;
  src: url(fonts/BurbankRussian.woff) format("woff") 
}

@font-face {
  font-family: "HeadingNow67Extrabold";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('fonts/HeadingNow/HeadingNow67Extrabold_normal_normal.woff2') format('woff2'), url('fonts/HeadingNow/HeadingNow67Extrabold_normal_normal.woff') format('woff');
}

body {
  margin: 0;
  font-family: HeadingNow67Extrabold, fortnite, Noto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(assets/desktop-epic-connect-bg-3.png);
  background-repeat:no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-color: black;
}

.russian{
  font-family: fortnite-cyrillic, Noto, sans-serif;
}

button{
  font-family: inherit;
  cursor: pointer;
}

p{
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: white;
  text-transform: uppercase;
}

.subtitle {
  color: white;
  max-width: 400px;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 110%;
  transform: matrix(0.97, 0, -0.24, 1, 0, 0);
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-feature-settings: 'titl' on;
  margin: 0.5em 0 0;
}

.link-wrapper{
  background: url(assets/button.svg) no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px 20px;
  border: none;
  color: black;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 47px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  min-width: 250px;
}

.content{
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  max-width: 32%;
  margin: 24vh 0 0 12vw;
}

.logo {
  max-width: 522px;
  width:100%;
}

.wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.heroImgWrapper{
  overflow: hidden;
}

.heroImgWrapper img{
  display: block;
  height: 90vh;
  margin-top: 10vh;
}

.modalOverlay{
  height: 100vh;
  width: 100vw;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalWrapper{
  height: 100vh;
  width: 100vw;
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalP{
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.1px;
  margin: 20px 40px 0;
  color: #777779;
  text-transform: none;
  text-align: left;
}

.modalP:first-of-type{
  margin-top: 40px;
}

.modalP a{
  color: #4678ff;
}

.modalList{
  color: white;
}

.modalAcceptButton{
  background-color: #4678FF;
  border: 1px solid #4678FF;
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  letter-spacing: 1.25px;
  padding: 10px 24px;
  border-radius: 20px;
  line-height: 20px;
  font-style: normal;
  text-transform: none;
  text-decoration: none;
}

.modalDenyButton{
  color: #4678FF;
  background-color: transparent;
  border: 1px solid #4678FF;
  font-weight: bold;
  font-size: 14px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  letter-spacing: 1.25px;
  padding: 10px 24px;
  border-radius: 20px;
  margin-right: 10px;
  max-width: 300px;
  line-height: 20px;
  width: 100%;
  text-transform: none;
}

.modalActions{
  display: flex;
  justify-content: center;
  padding: 50px 0 42px 0;
}

.modal{
  background-color: #232428;
  color: rgba(255, 255, 255, 0.87);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  position: relative;
  font-family: 'Open Sans', sans-serif;
}

.closeBtn{
  position: absolute;
  right: 0;
  margin: 13px 13px 0 0;
  background-color: transparent;
  border: none;
}

  @media only screen and (max-width: 770px)  {
    .wrapper{
      flex-direction: column;
      align-items: center;
    }

    .content{
      margin: 0;
      margin-top: 10%;
      max-width: 80%;
    }

    .heroImgWrapper{
      width: 140%;
      margin-right: -20px;
    }

    p{
      font-size: 20px;
    }

    .link-wrapper{
      font-size: 24px;
    }

    a{
      font-size: 2vh;
    }
  }
